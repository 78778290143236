import React from "react";
import "../Assets/css/style.css"; 
import Card from '../components/Card';
import Ads from "../Assets/images/ads.png";
import Apps from "../Assets/images/apps.png";
import Web from "../Assets/images/web.png";
import Fade from 'react-reveal/Fade';


class Solutions extends React.Component{
    render(){
        return(
            
            <div id="Solutions" className="Solutions">
                <Fade up>
                    <div className="Solutions_title">
                        <h2>SOLUTIONS</h2>
                        <p className="subtitle"> We’re a huge creative mind.<br/> We’re all dedicated to take your brand <br/><span className="bold"> to the next level.</span></p>
                    </div>
                </Fade>
                <div  className="Solutions_cards">
                <Fade right >
                    <Card   title="Social & Ads 👨‍💻" 
                            imagen={Ads} 
                            text="Create campaigns for your size, audience and necessities - including Google, Meta Ads, Youtube Ads and more! ⚡"
                    />
                </Fade>   
                <Fade bottom >
                    <Card   title="Webs & Apps 🤳" 
                            imagen={Apps} 
                            text="Build your Web or App that help you to be visible on the internet, increase your sales and improve your brand image. 🥷"
                    />
                </Fade>   
                <Fade left >
                    <Card   title="SEO & Email MKTNG 👨‍💻"
                            imagen={Web}
                            text=" Looking for expert SEO and email marketing services? Look no further than Infinix. Contact us today for a free consultation!"
                    />
                </Fade>   

                </div>  
            </div>    
   
        )
    }
}
export default Solutions;